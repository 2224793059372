import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import VuePureLightbox from "vue-pure-lightbox";
import "vue-pure-lightbox/dist/VuePureLightbox.css";import {CONTAINER_TYPES} from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";

@Component({
  components: {
    VuePureLightbox,
  },
})
export default class DfCardBannerComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  attachment: any;

  get attachmentPreviewUrl(): string {
    return this.attachment.image ? `${Utils.getContextPath(true)}/files/${this.attachment.uniqueId}/${this.attachment.basename}.${this.attachment.extension}` : "https://www.computerhope.com/jargon/a/attachment.png";
  }

  private onClick() {
    if (this.attachment.image) {
      (<HTMLElement>this.$el.getElementsByClassName("lightbox__thumbnail")[0]).click();
    } else {
      this.downloadAttachment();
    }
  }

  private downloadAttachment() {
    if (this.attachment) {
      this.service
        .downloadFile({ fileUniqueId: this.attachment.uniqueId, fileName: this.attachment.name })
        .then((file: any) => {
          Utils.downloadFile(file, `${this.attachment.basename}.${this.attachment.extension}`, Utils.getMimeFromExtension(this.attachment.extension));
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }
}
